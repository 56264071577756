import axios from 'axios'
import { getToken, removeToken } from '@/utils/auth'

const instance = axios.create({
  baseURL: '/web/',
  timeout: 30000
});
instance.interceptors.request.use(
	config => {
		const token = getToken()
		if (token) {
			config.headers['X-Token'] = token
		}
		return config
	},
	error => {
		console.log(error) // for debug
		return Promise.reject(error)
	}
)

instance.interceptors.response.use(
	response => {
		const res = response.data
		if (res && res.ret == -99) {
			removeToken()
		}
		return response
	},
	error => {
		console.log('err' + error) // for debug
		return Promise.reject(error)
	}
)

export default instance
