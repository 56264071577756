<template>
  <div class="index-container">
    {{token && nickname ? nickname + ',您是来看我印钞的吗?' : ''}}
    <div v-if="showChart" id="day-profit-chart" style="width: 100%;height: 400px;background-color:#FFF;padding: 20px 20px;"></div>
    <el-button type="primary" size="mini" @click="loadData">刷新</el-button>
    <el-button v-if="coinInfo && coinInfo['状态']" type="primary" size="mini" @click="showDayProfitChart">收益图表</el-button>
    <el-button v-if="!token" type="primary" size="mini" @click="showLogin">登录</el-button>
    <el-button v-if="token" type="primary" size="mini" @click="restart">重启</el-button>
    <el-select v-if="token" style="margin: 0 10px;" v-model="selectCoin" filterable placeholder="选择要跑的币">
      <el-option
        v-for="item in allowBases"
        :key="item + '_sel'"
        :label="item[0] + ':' + item[1] + '个交易所,' + (item[2] ? '仓位价值:' + item[2] : '无仓位') + (item[3] ? '|支持套保' + '|' + item[4] : '')"
        :value="item[0]">
      </el-option>
    </el-select>
    <el-button v-if="token" type="primary" size="mini" @click="addSelfCoin">增加</el-button>
    <el-button v-if="token" type="primary" size="mini" @click="subSelfCoin">减仓</el-button>
    <el-button v-if="token" type="primary" size="mini" @click="scrollToCoin">跳转</el-button>
    <div style="margin-top: 5px;" v-if="token && hopeAdd1Coins && hopeAdd1Coins.length > 0">强烈推荐:
      <template v-for="item in hopeAdd1Coins">
        <el-button :key="item" size="mini" type="success" @click="addNewCoin(item)">+{{item}}</el-button>
      </template>
    </div>
    <div style="margin-top: 5px;" v-if="token && hopeAdd2Coins && hopeAdd2Coins.length > 0">比较推荐:
      <template v-for="item in hopeAdd2Coins">
        <el-button :key="item" size="mini" type="info" @click="addNewCoin(item)">+{{item}}</el-button>
      </template>
    </div>
    <h5 v-if="coinInfo && coinInfo['状态']">基础信息</h5>
    <el-table
       v-if="coinInfo && coinInfo['状态']"
      :data="[coinInfo]"
      style="width: 100%"
      >
      <template v-for="item in coinTitles">
        <el-table-column
          :key="item"
          :prop="item"
          :label="item">
          <template slot-scope="scope">
            <template>
              <div>
                {{scope.row[item]}}<el-button v-if="token && fees.indexOf(item) >= 0" size="mini" :type="parseFloat(scope.row[item] || 0) < 3 ? 'primary':'info'" @click="addNewCoin(item)">加仓</el-button>
              </div>
            </template>
          </template>
        </el-table-column>
      </template>
    </el-table>
    <h5 v-if="config && config.baseBalance">套利配置</h5>
    <el-table
      v-if="config && config.baseBalance"
      :data="[config]"
      style="width: 100%"
      >
      <template v-for="item in configTitles.filter(e => !e.future || future)">
        <el-table-column
          :key="item.key"
          :prop="item.key"
          :label="item.title">
          <template slot-scope="scope">
            <template>
              <div @click="item.click(scope.row[item.key])">
                {{item.func ? item.func(scope.row[item.key]) : scope.row[item.key]}}
              </div>
            </template>
          </template>
        </el-table-column>
      </template>
    </el-table>
    <h5 v-if="platformCoins && Object.keys(platformCoins).length > 0">平台币</h5>
    <el-table
       v-if="platformCoins"
      :data="[platformCoins]"
      style="width: 100%"
      >
      <template v-for="item in platformCoinsTitles">
        <el-table-column
          :key="item"
          :prop="item"
          :label="item">
          <template slot-scope="scope">
            <template>
              <div>
                {{scope.row[item]}}<el-button v-if="token && fees.indexOf(item) >= 0" size="mini" :type="parseFloat(scope.row[item] || 0) < 3 ? 'primary':'info'" @click="addNewCoin(item)">加仓</el-button>
              </div>
            </template>
          </template>
        </el-table-column>
      </template>
    </el-table>
    <h5 v-if="profitInfo && Object.keys(profitInfo).length > 0">收益情况</h5>
    <template v-if="token && needDrawMap.length > 0">
      <h5>需提现币种</h5>
      <el-table
        :data="needDrawMap"
        style="width: 100%"
        >
        <template v-for="item in needDrawTitles">
          <el-table-column
              :key="item.key"
              :prop="item.key"
              :label="item.title">
          </el-table-column>
        </template>
      </el-table>
    </template>
    <el-table
      v-if="profitInfo"
      :data="[profitInfo]"
      style="width: 100%"
      >
      <template v-for="item in profitTitles">
        <el-table-column
          :key="item"
          :prop="item"
          :label="item">
        </el-table-column>
      </template>
    </el-table>
    <template v-if="token">
      <h5>交易所资金汇总</h5>
      <el-table
        :data="valueExTable"
        style="width: 100%"
        >
        <template v-for="item in valueInfoTitles">
          <el-table-column
              :key="item.key"
              :prop="item.key"
              :label="item.title">
          </el-table-column>
        </template>
      </el-table>
    </template>
    <template v-if="token && todayCoinValueCache.length > 0">
      <h5>今日资产变动
        <el-button size="mini" type="info" @click="isShowProfitInfo = !isShowProfitInfo">{{isShowProfitInfo ? '展示中' : '隐藏中'}}</el-button>
      </h5>
      <el-table
        :data="todayCoinValueCache.filter(e => isShowProfitInfo ? true : e.coin == '汇总')"
        style="width: 100%"
        >
        <template v-for="item in coinValueCacheTitles">
          <el-table-column
              :key="item.key"
              :prop="item.key"
              :label="item.title">
          </el-table-column>
        </template>
      </el-table>
    </template>
    <template v-if="token && predayCoinValueCache.length > 0">
      <h5>昨日资产变动
        <el-button size="mini" type="info" @click="isShowPreProfitInfo = !isShowPreProfitInfo">{{isShowPreProfitInfo ? '展示中' : '隐藏中'}}</el-button>
      </h5>
      <el-table
        :data="predayCoinValueCache.filter(e => isShowPreProfitInfo ? true : e.coin == '汇总')"
        style="width: 100%"
        >
        <template v-for="item in coinValueCacheTitles">
          <el-table-column
              :key="item.key"
              :prop="item.key"
              :label="item.title">
          </el-table-column>
        </template>
      </el-table>
    </template>
    <h5 v-if="priceInfo && priceInfo.length > 0">价格信息{{ priceInfo ? '(' + (priceInfo.length - 1) + ')' : '' }}
      <el-button size="mini" type="info" @click="isShowPriceInfo = !isShowPriceInfo">{{isShowPriceInfo ? '展示中' : '隐藏中'}}</el-button>
    </h5>
    <el-table
      ref="priceTable"
      v-if="isShowPriceInfo && priceInfo && priceInfo.length > 0"
      :data="priceInfo"
      highlight-current-row
      style="width: 100%"
      >
      <el-table-column
          key="symbol"
          prop="symbol"
          label="币对">
          <template slot-scope="scope">
            <template v-if="scope.row.symbol.indexOf('-') > 0">
              <template v-if="kcoins.indexOf(scope.row.symbol.split('-')[0]) >= 0">
                <span :id="scope.row.symbol.split('-')[0]" style="color:#00F;font-weight: bold;">{{scope.row.symbol}}</span>
              </template>
              <template v-else>
                <span :id="scope.row.symbol.split('-')[0]">{{scope.row.symbol}}</span>
              </template>
            </template>
            <template v-else>{{scope.row.symbol}}</template>
          </template>
      </el-table-column>
      <template v-for="item in exNames">
        <el-table-column
          :key="item"
          :prop="item"
          :label="item">
          <template slot-scope="scope">
            <template v-if="scope.row[item + 'no']">
              <span style="color: red;">{{scope.row[item]}}</span>
            </template>
            <template v-else>{{scope.row[item] || ''}}</template>
          </template>
        </el-table-column>
      </template>
      <el-table-column
        v-if="token"
        key="op"
        prop="op"
        label="操作">
        <template slot-scope="scope">
          <template v-if="scope.row.symbol.indexOf('-') > 0">
            <div>
              <el-button size="mini" type="primary" @click="openGrepLog(scope.row.symbol.split('-')[0])">日志</el-button><br/>
              <el-button style="margin-top:5px;" size="mini" type="primary" @click="addNewCoin(scope.row.symbol.split('-')[0])">加仓</el-button><br/>
              <el-button style="margin-top:5px;" size="mini" type="info" @click="delOldCoinMoney(scope.row.symbol.split('-')[0])">减仓</el-button><br/>
              <el-button style="margin-top:5px;" size="mini" type="warning" @click="delOldCoin(scope.row.symbol.split('-')[0])">清仓</el-button>
            </div>
          </template>
        </template>
      </el-table-column>
    </el-table>
    <p v-if="!token && priceInfo && priceInfo.length > 0" style="text-align: center">更多持仓，请登录后查看</p>
    <template v-if="token">
      <h5>闪兑</h5>
      <template v-for="exName in convertExs">
        <el-button size="mini" :key="exName" @click="showConvertList(exName)">{{exName}}</el-button>
      </template>
      <template>
        <el-button size="mini" @click="clearAll()">全部清仓</el-button>
      </template>
    </template>
    <template v-if="token">
      <h5>交易所持仓</h5>
      <el-table
        :data="coinExTable"
        style="width: 100%"
        >
        <template v-for="item in coinInfoTitles">
          <el-table-column
              :key="item.key"
              :prop="item.key"
              :label="item.title">
          </el-table-column>
        </template>
        <el-table-column
          key="op"
          prop="op"
          label="操作">
          <template slot-scope="scope">
            <template v-if="useCoins.indexOf(scope.row.symbol) < 0 && fees.indexOf(scope.row.symbol) < 0 && scope.row.symbol != 'USDT'">
              <div>
                <el-button size="mini" type="info" @click="addNewCoin(scope.row.symbol)">加仓</el-button>
                <el-button size="mini" type="info" @click="delOldCoin(scope.row.symbol)">清仓</el-button>
              </div>
            </template>
          </template>
        </el-table-column>
      </el-table>
    </template>
    <el-dialog
      title=""
      :visible.sync="loginDialogVisible"
      width="30%"
      :fullscreen="true"
      center>
      <div class="register-body">
        <div class="register-rows">
          <div class="register-title">登录</div>
          <el-form :model="loginForm" :rules="loginRules" ref="loginForm" label-width="0 !important">
            <div class="register-input-body">
              <div class="register-sub-title">用户名</div>
              <el-form-item prop="name">
                <el-input
                  ref="name"
                  autosize
                  :placeholder="请输入用户名"
                  v-model="loginForm.name">
                </el-input>
              </el-form-item>
            </div>
            <div class="register-input-body">
              <div class="register-sub-title">密码</div>
              <el-form-item prop="pwd">
                <el-input
                  autosize
                  :placeholder="请输入密码"
                  v-model="loginForm.pwd" show-password>
                </el-input>
              </el-form-item>
            </div>
          </el-form>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <div class="register-btn" @click="tryLogin">登录</div>
      </span>
    </el-dialog>
    <el-dialog
      title=""
      :visible.sync="convertListVisible"
      center>
      <template>
        <el-button size="mini" type="primary" @click="tryConvert()">闪兑</el-button>
        <el-table
          v-loading="convertLoading"
          ref="multipleTable"
          :data="convertCoins"
          tooltip-effect="dark"
          style="width: 100%"
          @selection-change="handleCheckedCoinsChange">
          <el-table-column
            type="selection"
            width="55">
          </el-table-column>
          <el-table-column
            prop="asset"
            label="币种">
          </el-table-column>
          <el-table-column
            prop="balance"
            label="数量">
          </el-table-column>
          <el-table-column
            prop="convertUsdt"
            label="价值(USDT)">
          </el-table-column>
          <el-table-column
            v-if="convertExName == 'mexc'"
            prop="convertMx"
            label="价值(MX)">
          </el-table-column>
        </el-table>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import * as echarts from 'echarts'
import { login, getUserInfo, getInfo, addCoin, delCoin, changeBaseBalance, changeDrawOut, changeHopePrecent, changeFirstMoney, changeOneMoney, changeOpenSize, restartCoin, changeBuySelf, changeOpenFuture, changeJustOpenCanFutureShort, getChartData, getConvertList, postConvert, postAuto, postPause } from '@/api/coin'
import { getToken, setToken, setCb } from '@/utils/auth'
import moment from 'moment'
const sp = (precent, dot) => {
  if (!dot) {
    dot = global.spDot || 5
  }
  if (precent && (precent + '').length - (parseInt(precent) + '').length - 1 > dot) {
    return parseFloat(precent).toFixed(dot)
  }
  return precent
}
function ctime(time) {
  if (time) {
    return moment(time).utc().utcOffset(8).format('YY-MM-DD HH:mm:ss.SSS')
  }
  return moment.utc().utcOffset(8).format('YY-MM-DD HH:mm:ss.SSS')
}
function getDotLen(stepSize) {
  stepSize = parseFloat(stepSize)
  let idx = 0
  while(stepSize < 0.999) {
    stepSize = stepSize * 10
    ++idx
  }
  return idx
}
function fixDot(price, stepSize, floor) {
  const func = floor ? Math.floor : Math.ceil
  return (func(price / stepSize) * stepSize + (stepSize < 1 ? stepSize / 100 : 0)).toFixed(getDotLen(stepSize))
}
export default {
  name: 'CoinInfo',
  data() {
    return {
      needClearAll: false,
      convertExs: ['mexc'],
      convertExName: '',
      convertListVisible: false,
      convertLoading: false,
      checkAll: false,
      checkedCoins: [],
      convertCoins: [],
      fees: ['MX', 'BNB', 'BGB', 'GT', 'KCS', 'HTX', 'CET'],
      coinCache: {},
      accounts: {},
      exchangeCoins: {},
      baseTitles: [
        {
          key: 'startTime',
          title: '起跑时间'
        },
        {
          key: 'runTime',
          title: '已运行'
        },
        {
          key: 'state',
          title: '状态'
        },
        {
          key: 'baseBalance',
          title: '起跑资金'
        },
        {
          key: 'curBalance',
          title: '实时资金'
        },
        {
          key: 'lastUpdate',
          title: '最后更新'
        }
      ],
      baseData: [],
      coinTitles: [],
      platformCoins: {},
      platformCoinsTitles: {},
      coinInfo: {},
      profitTitles: [],
      profitInfo: {},
      priceInfo: [],
      symbols: [],
      exNames: [],
      exSymbols: {},
      allowBases: [],
      accountInfo: {},
      useCoins: [],
      coinInfoTitles: [],
      coinExTable: [],
      valueExTable: [],
      valueInfoTitles: [],
      kcoins: [],
      vip: false,
      future: false,
      exHopeMoney: {},
      selectCoin: '',
      token: '',
      nickname: '',
      loginDialogVisible: false,
      loginForm: {
        name: '',
        pwd: ''
      },
      profits: [],
      showChart: false,
      config: {},
      configTitles: [
        {
          key: 'baseBalance',
          title: '起跑资金',
          click: this.changeBase
        },
        {
          key: 'drawOut',
          title: '已提现',
          click: this.changeDraw,
          func: (val) => {
            return val ? val : 0
          }
        },
        {
          key: 'firstMoney',
          title: '首仓金额',
          click: this.changeFirst
        },
        {
          key: 'oneMoney',
          title: '补仓金额',
          click: this.changeOne
        },
        {
          key: 'openSize',
          title: '补仓次数',
          click: this.changeSize
        },
        {
          key: 'buySelf',
          title: '自动买卖',
          func: (val) => {
            return val == 1 ? '手动买币' : '自动买币'
          },
          click: this.changeBuy
        },
        {
          key: 'pause',
          title: '是否套利',
          func: (val) => {
            return val ? '套利已暂停' : '套利中'
          },
          click: this.changePause
        },
        {
          key: 'openFuture',
          title: '做空套保',
          func: (val) => {
            return val == 1 ? '开启' : '关闭'
          },
          click: this.changeFuture,
          future: true
        },
        {
          key: 'justOpenCanFutureShort',
          title: '只买可套保币',
          func: (val) => {
            return val == 1 ? '开启' : '关闭'
          },
          click: this.changeFutureShort,
          future: true
        },
        {
          key: 'autoDel',
          title: '清币策略',
          func: (val) => {
            return val ? '清除无用' : '保持比例'
          },
          click: this.changeAuto
        },
        {
          key: 'hopePrecent',
          title: '期待持仓占比',
          click: this.changeHope
        },
      ],
      needDrawTitles: [
        {
          key: 'coin',
          title: '币种'
        },
        {
          key: 'fromEx',
          title: '来自'
        },
        {
          key: 'toEx',
          title: '划转到'
        },
        {
          key: 'value',
          title: '价值'
        }
      ],
      needDrawMap: [],
      coinValueCacheTitles: [
        {
          key: 'coin',
          title: '币种'
        },
        {
          key: 'amount',
          title: '数量'
        },
        {
          key: 'price0',
          title: '起跑价'
        },
        {
          key: 'price1',
          title: '结算价'
        },
        {
          key: 'profit',
          title: '盈亏'
        },
        {
          key: 'precent',
          title: '涨跌'
        },
        {
          key: 'stime',
          title: '起始时间'
        },
        {
          key: 'time',
          title: '结算时间'
        }
      ],
      predayCoinValueCache: [],
      todayCoinValueCache: [],
      isShowPriceInfo: true,
      isShowProfitInfo: false,
      isShowPreProfitInfo: false
    }
  },
  mounted() {
    let $this = this
    this.token = getToken() || ''
    setCb(() => {
      $this.token = ''
      $this.nickname = ''
    })
    this.loadData()
  },
  watch: {
    exSymbols() {
      if (!this.symbols) {
        return
      }
      this.priceInfo = []
      for(let symbol of this.symbols) {
        const pi = {
          symbol: symbol
        }
        for(let name of this.exNames) {
          if (this.exSymbols[name]) {
            if (this.exSymbols[name][symbol]) {
              pi[name] = (this.exSymbols[name][symbol] == 0 ? '0' : this.exSymbols[name][symbol]) || ''
            } else {
              const base = symbol.split('-')[0]
              if (this.accountInfo[name]) {
                const ac = this.accountInfo[name][base]
                if (ac && ac.value > 1) {
                  pi[name] = '无|' + parseFloat(ac.value).toFixed(2) + 'U'
                  pi[name + 'no'] = true
                }
              }
            }
          } else {
            pi[name] = ''
          }
        }
        
        this.priceInfo.push(pi)
      }
    },
    token() {
      this.loadData()
    },
    accountInfo() {
      this.needDrawMap = []
      for (let exName of Object.keys(this.accountInfo)) {
        const acc = this.accountInfo[exName]
        for (let coin of Object.keys(acc)) {
          if (coin == 'USDT') {
            continue
          }
          const c = acc[coin]
          if (!c.price && c.value > 0.1) {
            this.needDrawMap.push({
              fromEx: exName,
              toEx: c.toEx,
              coin: coin,
              value: c.value
            })
            console.log('可提现:', exName, '->', c.toEx, '币:', coin, '价值:', c.value)
          }
        }
      }
    }
  },
  methods: {
    loadData() {
      const $this = this
      getInfo($this.token).then((resp) => {
        $this.coinTitles = resp.data.coinTitles
        $this.platformCoins = resp.data.platformCoins
        $this.platformCoinsTitles = resp.data.platformCoinsTitles
        $this.coinInfo = resp.data.coinInfo
        $this.profitTitles = resp.data.profitTitles
        $this.profitInfo = resp.data.profitInfo
        $this.exNames = resp.data.exNames
        $this.symbols = resp.data.symbols
        $this.exSymbols = resp.data.exSymbols
        $this.allChains = resp.data.allChains
        $this.wfiCoins = resp.data.wfiCoins
        $this.cpCoins = resp.data.cpCoins
        $this.goodCoins = resp.data.goodCoins
        $this.useMod = resp.data.useMod
        $this.hopeAdd1Coins = resp.data.hopeAdd1Coins
        $this.hopeAdd2Coins = resp.data.hopeAdd2Coins
        $this.allowBases = resp.data.allowBases
        $this.profits = resp.data.profits
        $this.accountInfo = resp.data.accountInfo
        $this.useCoins = resp.data.useCoins
        $this.kcoins = resp.data.kcoins
        $this.vip = resp.data.vip
        $this.future = resp.data.future
        $this.exHopeMoney = resp.data.exHopeMoney
        $this.config = resp.data.config
        $this.updateCoinValueInfo(resp.data.coinValeCache)
        $this.updateAccountInfo()
        if ($this.coinInfo) {
          if ($this.coinInfo['状态'] == '重启中，请稍候') {
            $this.loadDataTimeout(10000)
          }
        }
        $this.closeOnePos()
      })
      if (!this.nickname) {
        this.loadInfo()
      }
    },
    loadInfo() {
      const $this = this
      const token = getToken()
      if (token && (!$this.token || !$this.nickname)) {
        getUserInfo(token).then(resp => {
          if (resp && resp.data && resp.data.data && resp.data.data.name) {
            $this.token = token
            $this.nickname = resp.data.data.nickname || resp.data.data.name
          }
        })
      }
    },
    updateCoinValueInfo(coinValueCache) {
      const today = coinValueCache.today
      const preday = coinValueCache.preday
      function updateCache(cache, arr) {
        if (!cache) {
          return
        }
        let totalProfit = 0
        let preValue = 0
        let curValue = 0
        for (let coin of Object.keys(cache)) {
          const ci = cache[coin]
          let tprofit = 0
          let coinArr = []
          for (let amount of Object.keys(ci)) {
            const cv = ci[amount]
            if (!cv.price0) {
              continue
            }
            const camount = parseFloat(amount)
            const price0 = parseFloat(cv.price0)
            const price1 = parseFloat(cv.price1)
            const profit = (price1 - price0) * camount
            const pvalue =  price0 * camount
            const cvalue =  price1 * camount
            const info = {
              coin: coin,
              amount: amount,
              price0: cv.tickSize ? fixDot(cv.price0, cv.tickSize) : cv.price0,
              price1: cv.tickSize ? fixDot(cv.price1, cv.tickSize) : cv.price1,
              profit: sp(profit, 2),
              precent: sp(pvalue, 2) + '->' + sp(cvalue, 2) + '(' + sp(((price1 - price0) / price0) * 100) + '%' + ')',
              stime: ctime(cv.stime),
              time: ctime(cv.time),
            }
            arr.push(info)
            coinArr.push(info)
            totalProfit += profit
            preValue += pvalue
            curValue += cvalue
            tprofit += profit
          }
          for (let info of coinArr) {
            info.tprofit = tprofit
          }
        }
        arr.sort((a, b) => {
          if (a.coin == b.coin) {
            return Math.abs(b.time) - Math.abs(a.time)
          }
          return Math.abs(b.tprofit) - Math.abs(a.tprofit)
        })
        arr.unshift({
          coin: '汇总',
          amount: '',
          price0: sp(preValue),
          price1: sp(curValue),
          profit: sp(totalProfit),
          precent: sp((curValue - preValue) / preValue * 100) + '%'
        })
      }
      this.todayCoinValueCache = []
      this.predayCoinValueCache = []
      updateCache(today, this.todayCoinValueCache)
      updateCache(preday, this.predayCoinValueCache)
    },
    updateAccountInfo() {
      if (!this.accountInfo) {
        return
      }
      const coinExNames = Object.keys(this.accountInfo)
      const coins = []
      const table = []
      const values = []
      const t = [{
        title: '币种',
        key: 'symbol'
      }]
      t.push(...coinExNames.map(e => {
        return {
          title: e,
          key: e
        }
      }))
      const vt = []
      vt.push(...t)
      t.push({
        title: '运行中',
        key: 'using'
      })
      t.push({
        title: '汇总',
        key: 'total'
      })
      vt.push({
        title: '汇总',
        key: 'total'
      })
      this.coinInfoTitles = t
      this.valueInfoTitles = vt
      const total = {}
      for (let exName of coinExNames) {
        for (let c of Object.keys(this.accountInfo[exName])) {
          if (coins.indexOf(c) < 0) {
            coins.push(c)
          }
          total[c] = (total[c] || 0) + this.accountInfo[exName][c].value
        }
      }
      const coinValueInfo = {
        symbol: '持仓币'
      }
      const platformCoinValueInfo = {
        symbol: '平台币'
      }
      const otherCoinValueInfo = {
        symbol: '其它币'
      }
      const totalValueInfo = {
        symbol: '汇总'
      }
      const hopeMoneyInfo = {
        symbol: '推荐'
      }
      for (let name of coinExNames) {
        otherCoinValueInfo[name] = 0
      }
      otherCoinValueInfo['total'] = 0
      for (let c of coins) {
        const coinInfo = {
          symbol: c
        }
        if (c == 'USDT') {
          const valueInfo = {
            symbol: c
          }
          for (let name of coinExNames) {
            valueInfo[name] = this.accountInfo[name][c] && this.accountInfo[name][c].value > 0.00001 ? sp(this.accountInfo[name][c].value) + 'U' : ''
          }
          valueInfo['total'] = sp(total[c] || 0) + 'U'
          values.push(valueInfo)
        } else if (this.fees.indexOf(c) >= 0) {
          for (let name of coinExNames) {
            platformCoinValueInfo[name] = (platformCoinValueInfo[name] || 0) + (this.accountInfo[name][c] && this.accountInfo[name][c].value > 0 ? parseFloat(this.accountInfo[name][c].value) : 0)
          }
          platformCoinValueInfo['total'] = (platformCoinValueInfo['total'] || 0) + (total[c] || 0)
        } else if (this.useCoins.indexOf(c) >= 0) {
          for (let name of coinExNames) {
            coinValueInfo[name] = (coinValueInfo[name] || 0) + (this.accountInfo[name][c] && this.accountInfo[name][c].value > 0 ? parseFloat(this.accountInfo[name][c].value) : 0)
          }
          coinValueInfo['total'] = (coinValueInfo['total'] || 0) + (total[c] || 0)
        } else {
          for (let name of coinExNames) {
            otherCoinValueInfo[name] = (otherCoinValueInfo[name] || 0) + (this.accountInfo[name][c] && this.accountInfo[name][c].value > 0 ? parseFloat(this.accountInfo[name][c].value) : 0)
          }
          otherCoinValueInfo['total'] = (otherCoinValueInfo['total'] || 0) + (total[c] || 0)
        }
        
        for (let name of coinExNames) {
          coinInfo[name] = this.accountInfo[name][c] && this.accountInfo[name][c].value > 0.00001 ? parseFloat(this.accountInfo[name][c].value).toFixed(5) + 'U' : ''
          totalValueInfo[name] = (totalValueInfo[name] || 0) + (this.accountInfo[name][c] && this.accountInfo[name][c].value > 0 ? parseFloat(this.accountInfo[name][c].value) : 0)
        }
        totalValueInfo['total'] = (totalValueInfo['total'] || 0) + (total[c] || 0)

        coinInfo['using'] = this.useCoins.indexOf(c) >= 0 ? '是' : '否'
        coinInfo['total'] = total[c] || 0
        if (coinInfo.total > 0.00001) {
          table.push(coinInfo)
        }
      }
      function parseSp(val, disTotal) {
        const keys = [...coinExNames]
        if (!disTotal) {
          keys.push('total')
        }
        for (let k of keys) {
          val[k] = sp(val[k] || 0) + 'U'
        }
        return val
      }
      if (coinExNames.length > 0) {
        values.push(parseSp(coinValueInfo))
        values.push(parseSp(platformCoinValueInfo))
        values.push(parseSp(otherCoinValueInfo))
        values.push(parseSp(totalValueInfo))
      }

      if (this.vip) {
        for (let exName of coinExNames) {
          hopeMoneyInfo[exName] = this.exHopeMoney[exName]
        }
        values.push(parseSp(hopeMoneyInfo, true))
      }

      table.sort((a, b) => b.total - a.total)
      for (let col of table) {
        col.total = parseFloat(col.total).toFixed(5) + 'U'
      }
      this.coinExTable = table
      this.valueExTable = values
    },
    showDayProfitChart() {
      this.showChart = !this.showChart
      if (!this.showChart) {
        return
      }
      getChartData().then(resp => {
        const profits = resp.data.dayProfits
        const chartDom = document.getElementById('day-profit-chart');
        const profitChart = echarts.init(chartDom);
        profitChart.setOption({
          title: {
            text: '每日收益统计'
          },
          tooltip: {
            trigger: 'axis',
            formatter: function (params) {
              params = params[0];
              var date = params.name;
              return (
                date + ' : ' + params.value
              );
            },
            axisPointer: {
              animation: false
            }
          },
          xAxis: {
            type: 'category',
            data: profits.map(e => moment(e[0]).format('YYYY-MM-DD'))
          },
          yAxis: {
            type: 'value'
          },
          series: [
            {
              name: 'Profit',
              type: 'line',
              showSymbol: false,
              data: profits.map(e => {
                return {
                  name: moment(e[0]).format('YYYY-MM-DD'),
                  value: parseFloat(e[1]).toFixed(2)
                }
              })
            // },
            // {
            //   data: profits.map(e => {
            //     const profit = e[1]
            //     return {
            //       value: parseFloat(profit).toFixed(2),
            //       // itemStyle: {
            //       //   color: profit < 20 ? '#42b983' : (profit < 50 ? '#42b983' : '#42b983')
            //       // }
            //     }
            //   }),
            //   // label: {
            //   //   show: true,
            //   //   position: 'top'
            //   // },
            //   type: 'line',
            //   smooth: true
            }
          ]
        }, true)
      })
    },
    addSelfCoin() {
      if (this.selectCoin) {
        this.addNewCoin(this.selectCoin)
      }
      login()
    },
    subSelfCoin() {
      if (this.selectCoin) {
        this.delOldCoinMoney(this.selectCoin)
      }
      login()
    },
    scrollToCoin() {
      if (this.selectCoin) {
        window.location.hash = '#' + this.selectCoin
        const rows = this.priceInfo.filter(e => e.symbol.split('-')[0] == this.selectCoin)
        if (rows.length > 0) {
          this.$refs.priceTable.setCurrentRow(rows[0])
        }
      }
    },
    openGrepLog(symbol) {
      window.open('/web/public/log?name=coin&line=100000&grep1=' + symbol + '&grep2=自动', '_blank');
    },
    addNewCoin(coin) {
      const $this = this
      this.$prompt('请输入增加' + coin + '持仓的金额', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        center: true
      }).then(({ value }) => {
        if (value > 500) {
          $this.$message({
            type: 'error',
            message: '出于安全考虑，单币加仓金额最大为500U'
          })
          return
        }
        addCoin(coin, value).then((resp) => {
          if (resp && resp.data && resp.data && resp.data.ret != 0) {
            if (resp.data.ret == -1) {
              $this.$message({
                type: 'error',
                message: '出于安全考虑，单币加仓金额最大为500U'
              })
              return
            }
            if (resp.data.ret == -3) {
              $this.$message({
                type: 'error',
                message: '出于安全考虑，同币种清仓后要等待10分钟才允许加仓'
              })
              return
            }
            $this.$message({
              type: 'error',
              message: '加仓失败'
            })
            return
          }
          $this.$message({
            type: 'success',
            message: '增加' + coin + '持仓请求成功, 金额:' + value
          })
        })
        setTimeout(() => {
          $this.loadData()
        }, 2000)
      }).catch(() => {   
      })
    },
    delOldCoinMoney(coin) {
      const $this = this
      this.$prompt('请输入' + coin + '减仓的金额', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        center: true
      }).then(({ value }) => {
        if (!value || value == null || value == 'null') {
          value = ''
        }
        delCoin(coin, value).then((resp) => {
          if (resp && resp.data && resp.data && resp.data.ret != 0) {
            if (resp.data.ret == -3) {
              $this.$message({
                type: 'error',
                message: '出于安全考虑，同币种加仓后要等待10分钟才允许减仓'
              })
              return
            }
            $this.$message({
              type: 'error',
              message: '减仓失败'
            })
            return
          }
          $this.$message({
            type: 'success',
            message: '减仓' + coin + '请求成功, 金额:' + value
          })
        })
        setTimeout(() => {
          $this.loadData()
        }, 2000)
      }).catch(() => {   
      })
    },
    delOldCoin(coin) {
      const $this = this
      this.$confirm('是否确认清空' + coin + '的全部持仓?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true
      }).then(() => {
        delCoin(coin).then((resp) => {
          if (resp && resp.data && resp.data && resp.data.ret != 0) {
            if (resp.data.ret == -3) {
              $this.$message({
                type: 'error',
                message: '出于安全考虑，同币种加仓后要等待10分钟才允许清仓'
              })
              return
            }
            $this.$message({
              type: 'error',
              message: '清仓失败'
            })
            return
          }
          $this.$message({
            type: 'success',
            message: '清仓' + coin + '请求成功'
          })
        })
        setTimeout(() => {
          $this.loadData()
        }, 2000)
      }).catch(() => {       
      })
    },
    changeBase() {
      const $this = this
      this.$prompt('请输入新的起跑资金', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        center: true
      }).then(({ value }) => {
        changeBaseBalance(value).then(() => {
          $this.$message({
            type: 'success',
            message: '变更起跑资金请求成功, 起跑资金:' + value
          })
        })
        setTimeout(() => {
          $this.loadData()
        }, 2000)
      }).catch(() => {   
      })
    },
    changeDraw() {
      const $this = this
      this.$prompt('请输入新的已提现资金', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        center: true
      }).then(({ value }) => {
        changeDrawOut(value).then(() => {
          $this.$message({
            type: 'success',
            message: '变更已提现资金请求成功, 已提现资金:' + value
          })
        })
        setTimeout(() => {
          $this.loadData()
        }, 2000)
      }).catch(() => {   
      })
    },
    changeHope() {
      const $this = this
      this.$prompt('请输入新的期待持仓占比%，范围:10~50', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        center: true
      }).then(({ value }) => {
        if (value < 10 || value > 50) {
          $this.$message({
            type: 'error',
            message: '期待持仓占比要在10~50之间'
          })
          return
        }
        changeHopePrecent(value).then(() => {
          $this.$message({
            type: 'success',
            message: '变更期待持仓占比请求成功, 期待持仓占比:' + value + '%'
          })
        })
        setTimeout(() => {
          $this.loadData()
        }, 2000)
      }).catch(() => {   
      })
    },
    changeFirst() {
      const $this = this
      this.$prompt('请输入新的首仓金额，范围:11~1000', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        center: true
      }).then(({ value }) => {
        if (value < 11 || value > 1000) {
          $this.$message({
            type: 'error',
            message: '期待首仓金额要在11~1000之间'
          })
          return
        }
        changeFirstMoney(value).then(() => {
          $this.$message({
            type: 'success',
            message: '变更首仓金额请求成功,首仓金额:' + value + ''
          })
        })
        setTimeout(() => {
          $this.loadData()
        }, 2000)
      }).catch(() => {   
      })
    },
    changeOne() {
      const $this = this
      this.$prompt('请输入新的补仓金额，范围:11~1000', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        center: true
      }).then(({ value }) => {
        if (value < 11 || value > 1000) {
          $this.$message({
            type: 'error',
            message: '期待补仓金额要在11~1000之间'
          })
          return
        }
        changeOneMoney(value).then(() => {
          $this.$message({
            type: 'success',
            message: '变更补仓金额请求成功,首仓金额:' + value + ''
          })
        })
        setTimeout(() => {
          $this.loadData()
        }, 2000)
      }).catch(() => {   
      })
    },
    changeSize() {
      const $this = this
      this.$prompt('请输入新的补仓次数，范围:1~30', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        center: true
      }).then(({ value }) => {
        if (value < 1 || value > 30) {
          $this.$message({
            type: 'error',
            message: '期待补仓次数要在1~30之间'
          })
          return
        }
        changeOpenSize(value).then(() => {
          $this.$message({
            type: 'success',
            message: '变更补仓次数请求成功,首仓金额:' + value + ''
          })
        })
        $this.loadDataTimeout()
      }).catch(() => {   
      })
    },
    loadDataTimeout(timeout) {
      const $this = this
      setTimeout(() => {
        $this.loadData()
      }, timeout || 2000)
    },
    showLogin() {
      this.loginDialogVisible = true
    },
    tryLogin() {
      const $this = this
      login({
        name: this.loginForm.name,
        password: this.loginForm.pwd
      }).then(resp => {
        this.loginDialogVisible = false
        const data = resp.data
        if (data.ret == 0 && data.token) {
          setToken(data.token)
          $this.token = data.token
          $this.loadInfo()
          $this.$message({
            type: 'success',
            message: '登录成功'
          })
        } else {
          $this.$message({
            type: 'error',
            message: '帐户或密码错误'
          })
        }
      })
      console.log(this.loginForm)
    },
    showConvertList(exName) {
      const $this = this
      this.convertExName = exName
      this.convertLoading = true
      getConvertList(exName).then(resp => {
        $this.convertLoading = false
        if (resp.data && resp.data.ret == 0) {
          $this.convertListVisible = true
          $this.convertCoins = resp.data.data.filter(e => !e.code && !e.message)
        }
      })
    },
    tryConvert() {
      if (this.checkedCoins.length == 0) {
        this.$message.warning('请选择币种');
        return
      }
      this.convertLoading = true
      const $this = this
      postConvert(this.convertExName, this.checkedCoins.map(e => e.asset)).then(resp => {
        if (resp.data && resp.data.ret == 0) {
          $this.$message.success('闪兑成功');
          $this.showConvertList($this.convertExName)
        } else {
          $this.$message.error('闪兑失败，请更新币种重试');
          $this.convertLoading = false
        }
      })
    },
    handleCheckedCoinsChange(val) {
      const maxSize = this.convertExName == 'mexc' ? 5 : 1000
      if (val && val.length > maxSize) {
        if (val.length == this.convertCoins.length && this.checkedCoins.length == 5) {
          val = []
          this.$refs.multipleTable.clearSelection()
        } else {
          val = val.slice(0, maxSize)
          this.$refs.multipleTable.clearSelection()
          val.forEach(row => {
            this.$refs.multipleTable.toggleRowSelection(row);
          })
        }
      }
      this.checkedCoins = val
    },
    changeAuto(val) {
      const $this = this
      this.$confirm('是否自动清空收益低的持仓，若选择保留比例，则在实际持仓比例低于期待持仓比例时，不清除收益低的持仓币', '提示', {
        confirmButtonText: '清空无用',
        cancelButtonText: '保留比例',
        type: 'warning'
      }).then(() => {
        if (!val) {
          postAuto(true).then(resp => {
            if (resp.data && resp.data.ret == 0) {
              $this.$message.success('设置清空无用成功');
            } else {
              $this.$message.error('设置清空无用失败');
            }
            $this.loadDataTimeout()
          })
        } else {
          $this.$message.info('当前已是清空无用');
        }
      }).catch(() => {
        if (val) {
          postAuto(false).then(resp => {
            if (resp.data && resp.data.ret == 0) {
              $this.$message.success('设置保留比例成功');
            } else {
              $this.$message.error('设置保留比例失败');
            }
            $this.loadDataTimeout()
          })
        } else {
          $this.$message.info('当前已是保留比例');
        }
      })
    },
    changeBuy(val) {
      const $this = this
      this.$confirm('是否手动买卖推荐币(不含平台币)', '提示', {
        confirmButtonText: '手动买卖',
        cancelButtonText: '自动买卖',
        type: 'warning'
      }).then(() => {
        if (val != 1) {
          changeBuySelf(true).then(resp => {
            if (resp.data) {
              $this.$message.success('设置手动买卖成功');
            } else {
              $this.$message.error('设置手动买卖失败');
            }
            $this.loadDataTimeout()
          })
        } else {
          $this.$message.info('当前已是手动买卖');
        }
      }).catch(() => {
        if (val == 1) {
          changeBuySelf(false).then(resp => {
            if (resp.data) {
              $this.$message.success('设置自动买卖成功');
            } else {
              $this.$message.error('设置自动买卖失败');
            }
            $this.loadDataTimeout()
          })
        } else {
          $this.$message.info('当前已是自动买卖');
        }
      })
    },
    changeFuture(val) {
      const $this = this
      this.$confirm('是否开启套保', '提示', {
        confirmButtonText: '开启套保',
        cancelButtonText: '关闭套保',
        type: 'warning'
      }).then(() => {
        if (val != 1) {
          changeOpenFuture(true).then(resp => {
            if (resp.data) {
              $this.$message.success('设置开启套保成功');
            } else {
              $this.$message.error('设置开启套保失败');
            }
            $this.loadDataTimeout()
          })
        } else {
          $this.$message.info('当前已是开启套保');
        }
        
      }).catch(() => {
        if (val == 1) {
          changeOpenFuture(false).then(resp => {
            if (resp.data) {
              $this.$message.success('设置关闭套保成功');
            } else {
              $this.$message.error('设置关闭套保失败');
            }
            $this.loadDataTimeout()
          })
        } else {
          $this.$message.info('当前已是关闭套保');
        }
      })
    },
    changeFutureShort(val) {
      const $this = this
      this.$confirm('是否只买可套保币', '提示', {
        confirmButtonText: '只买可套保币',
        cancelButtonText: '买全部币',
        type: 'warning'
      }).then(() => {
        if (val != 1) {
          changeJustOpenCanFutureShort(true).then(resp => {
            if (resp.data) {
              $this.$message.success('设置只买可套保币成功');
            } else {
              $this.$message.error('设置只买可套保币失败');
            }
            $this.loadDataTimeout()
          })
        } else {
          $this.$message.info('当前已是只买可套保币');
        }
      }).catch(() => {
        if (val == 1) {
          changeJustOpenCanFutureShort(false).then(resp => {
            if (resp.data) {
              $this.$message.success('设置买全部币成功');
            } else {
              $this.$message.error('设置买全部币失败');
            }
            $this.loadDataTimeout()
          })
        } else {
          $this.$message.info('当前已是买全部币');
        }
      })
    },
    changePause(val) {
      const $this = this
      this.$confirm('是否暂停套利', '提示', {
        confirmButtonText: '暂停套利',
        cancelButtonText: '继续套利',
        type: 'warning'
      }).then(() => {
        if (!val) {
          postPause(true).then(resp => {
            if (resp.data && resp.data.ret == 0) {
              $this.$message.success('设置暂停套利成功');
            } else {
              $this.$message.error('设置暂停套利失败');
            }
            $this.loadDataTimeout()
          })
        } else {
          $this.$message.info('当前已是暂停套利');
        }
      }).catch(() => {
        if (val) {
          postPause(false).then(resp => {
            if (resp.data && resp.data.ret == 0) {
              $this.$message.success('设置继续套利成功');
            } else {
              $this.$message.error('设置继续套利失败');
            }
            $this.loadDataTimeout()
          })
        } else {
          $this.$message.info('当前已是套利中');
        }
      })
    },
    restart() {
      const $this = this
      this.$confirm('是否重启策略', '提示', {
        confirmButtonText: '重启',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        restartCoin().then(() => {
          $this.loadDataTimeout()
        })
      }).catch(() => {
      })
    },
    clearAll() {
      if (!this.config.pause) {
        this.$message.error('此功能仅允许在暂停套利后使用!');
        return
      }
      const $this = this
      this.$confirm('是否要清空所有仓位', '提示', {
        confirmButtonText: '清空',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        $this.needClearAll = true
        $this.closeOnePos()
      }).catch(() => {
      })
    },
    closeOnePos() {
      if (!this.needClearAll) {
        return
      }
      let pinfo = this.priceInfo.filter(e => e.symbol.indexOf('-') > 0).map(e => e.symbol)
      if (pinfo.length == 0) {
        this.needClearAll = false
      }
      const $this = this
      delCoin(pinfo[pinfo.length - 1].split('-')[0]).then(() => {
        $this.loadDataTimeout()
      })
    }
  }
}
</script>

<style lang="scss" scoped>
$bg: #2d3a4b;
$dark_gray: #889aa4;
$light_gray: #eee;
$primary_color: #0143cc;
$max_width: 1280px;
.coin-options {
  display: flex;
  justify-content: flex-start;
}
html {
  background-color: #FFF !important;
}
.register-body {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  .register-rows {
    width: 400px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .register-title {
      font-family: Inter,sans-serif;
      text-align: center;
      font-size: 24px;
      color: #212529;
      font-weight: 700;
    }
    .register-input-body {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      margin-top: 2rem;
      .register-sub-title {
        line-height: 1.5;
        color: #212529;
        text-align: left;
        font-weight: 800;
        font-size: 14px;
        padding-left: 10px;
        margin-bottom: 5px;
        font-family: Inter UI,sans-serif!important;
      }
    }
  }
}
</style>
