import request from '../utils/request'

export function login(data) {
  return request({
    url: '/coin/user/login',
    method: 'post',
    data
  })
}

export function getUserInfo(token) {
  return request({
    url: '/coin/user/get',
    method: 'get',
    params: { token }
  })
}

export function getInfo(token) {
  return request.request({
    url: token ? '/coin/all' :'/coin/data',
    method: 'get'
  })
}

export function addCoin(coin, money) {
  return request.request({
    url: '/coin/add?coin=' + coin + '&money=' + (money || ''),
    method: 'get'
  })
}

export function delCoin(coin, money) {
  return request.request({
    url: '/coin/del?coin=' + coin + '&money=' + (money || ''),
    method: 'get'
  })
}

export function changeBaseBalance(baseBalance) {
  return request.request({
    url: '/coin/cache?baseBalance=' + baseBalance,
    method: 'get'
  })
}

export function changeDrawOut(drawOut) {
  return request.request({
    url: '/coin/cache?drawOut=' + drawOut,
    method: 'get'
  })
}

export function changeHopePrecent(hopePrecent) {
  return request.request({
    url: '/coin/cache?hopePrecent=' + hopePrecent,
    method: 'get'
  })
}

export function changeFirstMoney(firstMoney) {
  return request.request({
    url: '/coin/cache?firstMoney=' + firstMoney,
    method: 'get'
  })
}

export function changeOneMoney(oneMoney) {
  return request.request({
    url: '/coin/cache?oneMoney=' + oneMoney,
    method: 'get'
  })
}

export function changeOpenSize(openSize) {
  return request.request({
    url: '/coin/cache?openSize=' + openSize,
    method: 'get'
  })
}

export function changeBuySelf(buySelf) {
  return request.request({
    url: '/coin/cache?buySelf=' + (buySelf ? 1 : 2),
    method: 'get'
  })
}

export function changeOpenFuture(openFuture) {
  return request.request({
    url: '/coin/cache?openFuture=' + (openFuture ? 1 : 2),
    method: 'get'
  })
}
export function restartCoin() {
  return request.request({
    url: '/public/rs?name=coin',
    method: 'get'
  })
}

export function changeJustOpenCanFutureShort(justOpenCanFutureShort) {
  return request.request({
    url: '/coin/cache?justOpenCanFutureShort=' + (justOpenCanFutureShort ? 1 : 2),
    method: 'get'
  })
}

export function getChartData() {
  return request.request({
    url: '/coin/chart',
    method: 'get'
  })
}

export function getConvertList(exName) {
  return request.request({
    url: '/coin/convert/list?exName=' + exName,
    method: 'get'
  })
}

export function postConvert(exName, asset) {
  return request.request({
    url: '/coin/convert/post?exName=' + exName + '&asset=' + asset,
    method: 'get'
  })
}

export function postAuto(del) {
  return request.request({
    url: '/coin/auto?del=' + del,
    method: 'get'
  })
}

export function postPause(pause) {
  return request.request({
    url: '/coin/pause?pause=' + pause,
    method: 'get'
  })
}