<template>
  <CoinInfo/>
</template>

<script>
import CoinInfo from './components/CoinInfo.vue'

export default {
  name: 'App',
  components: {
    CoinInfo
  }
}
</script>

<style>
</style>
