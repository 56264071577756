import Cookies from 'js-cookie'

const TokenKey = 'cf_token'
let cb = null
export function setCb(func) {
	cb = func
}
export function getToken() {
	return Cookies.get(TokenKey)
}

export function setToken(token) {
	return Cookies.set(TokenKey, token)
}

export function removeToken() {
	Cookies.remove(TokenKey)
	if (cb) {
		cb()
	}
}
